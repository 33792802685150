header {
    height: auto;
    padding: 0;
    position: fixed;
    width: 100%;
    z-index: 999;
  }
  
  header i.icon {
    position: absolute;
    right: 30px;
    top: 32px;
    cursor: pointer;
    z-index: 10;
    display: none;
  }
  
  .grid-3 {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 1rem;
  }
  
  header .logo h2 {
    font-size: 30px;
    color: #fff;
  }
  
  header nav .nav-list {
    list-style: none;
    margin-bottom: 0;
    display: flex;
    justify-content: space-around;
  }
  
  header .navbar-light .navbar-nav .nav-link {
    display: block;
    padding: 5px 0.3rem;
    margin: 9px 0.2rem;
    font-size: 1rem;
    color: #fff;
    text-decoration: none;
    transition: all 0.3s ease-in-out;
  }
  
  .nav-link a {
    color: rgb(207, 203, 203);
  }
  
  a.nav-line {
    text-decoration: none;
    border: 3px solid rgb(23, 33, 80);
    color: rgb(23, 33, 80);
    border-radius: 10px;
    padding: 0.2rem 1rem;
    margin: 0 0.7rem;
  }
  
  a.nav-line:hover {
    color: #fff;
    background: rgb(23, 33, 80);
    transition: all 0.3s ease-in-out;
  }
  
  header .navbar-light .navbar-nav .lang {
    padding: 0;
    margin: 0 !important;
  }
  
  .image-details {
    max-width: 90%;
  }
  
  header .navbar-light .navbar-nav .nav-link:hover, header .navbar-light .navbar-nav a:hover {
    color: #75b1da;
  }
  
  .sticky {
    background: rgba(134, 137, 146, 0.9);
    box-shadow: 0 2px 10px 0 rgb(116, 97, 97);
    color: rgb(8, 4, 4) !important;
    transition: all 0.3s ease-in-out;
  }
  .sticky .navbar-light .navbar-nav .nav-link {
    color: #fff !important;
    transition: all 0.4s ease-in-out;
  }
  .sticky .nav-link a {
    color:  #fff;
    transition: all 0.4s ease-in-out;
  }
  
  .sticky .logo h2 {
    color: #000;
  }
  .navbar-light .navbar-toggler {
    border: 1px solid #fff !important;
  color: #ccc !important;
  border-color: rgba(255, 255, 255, 0.8);
}
  @media (max-width: 575px) {
  
    header {
      background: #00000065;
    }
    header .logo {
      text-align: center;
    }
    header nav .nav-list {
      padding: 0;
      text-align: center;
      display: none;
    }
    header i.icon {
      display: block;
    }
  }
  