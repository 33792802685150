 
body
{
  color:#fff;
}

.text-teal {
  color: rgb(255, 255, 255);
  background: rgb(43,124,164);
background: linear-gradient(90deg, rgba(43,124,164,1) 26%, rgba(94,212,140,1) 57%, rgba(80,157,140,1) 67%, rgba(76,235,176,1) 81%);
}
.footer-bs {
  background-color: #3c3d41;
padding: 60px 40px;
color: rgba(255,255,255,1.00);
margin-bottom: 20px;
border-bottom-right-radius: 6px;
border-top-left-radius: 0px;
border-bottom-left-radius: 6px;
}
.footer-bs .footer-brand, .footer-bs .footer-nav, .footer-bs .footer-social, .footer-bs .footer-ns { padding:10px 25px; }
.footer-bs .footer-nav, .footer-bs .footer-social, .footer-bs .footer-ns { border-color: transparent; }
.footer-bs .footer-brand h2 { margin:0px 0px 10px; }
.footer-bs .footer-brand p { font-size:12px; color:rgba(255,255,255,0.70); }

.footer-bs .footer-nav ul.pages { list-style:none; padding:0px; }
.footer-bs .footer-nav ul.pages li { padding:5px 0px;}
.footer-bs .footer-nav ul.pages a { color:rgba(255,255,255,1.00); font-weight:bold; text-transform:uppercase; }
.footer-bs .footer-nav ul.pages a:hover { color:rgba(255,255,255,0.80); text-decoration:none; }
.footer-bs .footer-nav h4 {
font-size: 11px;
text-transform: uppercase;
letter-spacing: 3px;
margin-bottom:10px;
}

.footer-bs .footer-nav ul.list { list-style:none; padding:0px; }
.footer-bs .footer-nav ul.list li { padding:5px 0px;}
.footer-bs .footer-nav ul.list a { color:rgba(255,255,255,0.80); }
.footer-bs .footer-nav ul.list a:hover { color:rgba(255,255,255,0.60); text-decoration:none; }

.footer-bs .footer-social ul { list-style:none; padding:0px; }
.footer-bs .footer-social h4 {
font-size: 11px;
text-transform: uppercase;
letter-spacing: 3px;
}
.footer-bs .footer-social li { padding:5px 4px;}
.footer-bs .footer-social a { color:rgba(255,255,255,1.00);}
.footer-bs .footer-social a:hover { color:rgba(255,255,255,0.80); text-decoration:none; }

.footer-bs .footer-ns h4 {
font-size: 11px;
text-transform: uppercase;
letter-spacing: 3px;
margin-bottom:10px;
}
.footer-bs .footer-ns p { font-size:12px; color:rgba(255,255,255,0.70); }

@media (min-width: 768px) {
.footer-bs .footer-nav, .footer-bs .footer-social, .footer-bs .footer-ns { border-left:solid 1px rgba(255,255,255,0.10); }
}
li.nav-item a{color:#fff;transition:all ease-in-out 0.3s;}
li.nav-item:hover a {color:#333;}
.spacial-features .feature p,.spacial-features .feature h3{color:#bbbbbb;}
.feature {padding:13px;transition:all ease-in-out 0.2s;border:1px solid transparent;border-radius:4px;}
.sectionD:hover .feature{cursor:pointer;border:1px solid #aaa;border:1px solid #678;background:#1e2021}
.sectionDh {cursor:pointer;border:1px solid #aaa;border:1px solid #678;background:#1e2021}

.globe_seperator {cursour:pointer;position:relative;text-align:center;line-height:56px;color:#333;font-size:32px;margin:0px auto;width:50px;height:50px;background:#eee;display:block;z-index:12;border-radius:50%;margin-top:-20px;}
.badge_holder{width:50px;height:100px;overflow:hidden;position:absolute;top:0px;}

.badge_box{
background:#aaa;
width:100px;
height:100px;
transform:rotate(45deg);
margin-left:-25px;
margin-top:-25px;
text-align:center;
} 
.badge_box_2{width:100px;height:100px;transform:rotate(45deg);margin-left:-25px;
background:#428bca;margin-top:-50px;position:absolute;z-index:2;
}
.badge_icon {position:absolute;top:10px;z-index:3;font-size:25px;width:100%;text-align:center;}
.infoBox {
background:url(./images/infoBox.jpg);
background-size:cover;background-position:center 0;overflow:hidden;position:relative;
min-height:100px;padding-bottom:10px;
}



.goals {
  clip-path: polygon(0 0, 100% 1%, 100% 94%, 0 100%);
  background-image: url(./images/infoBox.jpg);
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  position: relative;
  color: #fff;
}
.equipment{
  clip-path: polygon(0 0, 100% 1%, 100% 94%, 0 100%);
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  position: relative;
  color: #fff;
}
.business-hero{
  background: rgb(1,11,11);
  background: linear-gradient(90deg, rgba(1,11,11,1) 26%, rgba(66,159,164,1) 57%, rgba(58,107,124,1) 67%, rgba(76,168,235,1) 81%);
overflow:hidden;
position:relative;
min-height:20vh;
z-index:-10;
clip-path: polygon(0 0, 100% 0, 100% 100%, 0 94%)

}

.overlay {
  background: rgba(0, 0, 0, 0.7);
}

.nav-tabs .nav-link.active {
  color: #fff;
  background-color: #7cb62f;
  border-color: #dee2e6;
  border-bottom: none;
}

.nav-tabs a {
  text-decoration: none;
  color: #7cb62f;
}


.header__overlay{
  position:absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
}

#category { 
  color : rgb(34, 32, 32)
}

/* contact ========= */

.contact {
  background: #f7f7f7;
}

.contact .contact-info .contact-info-desc {
  color: #888;
  line-height: 1.6;
}

.contact .contact-info span {
  display: block;
  margin-bottom: 10px;
}

.contact .contact-info span i {
  border: 1px solid #7cb62f;
  width: 40px;
  height: 40px;
  text-align: center;
  transition: all 0.3s ease-in-out;
  line-height: 40px;
  border-radius: 50%;
  margin-right: 10px;
  color: #7cb62f;
}

.contact .contact-info span:hover i {
  background: #7cb62f;
  color: #fff;
}

.contact form .form-group {
  display: flex;
}

.contact form .form-control:focus {
  border-color: #888;
}

.contact form .form-btn {
  background: #7cb62f;
  padding: 10px 20px;
  margin: 10px auto;
  border: none;
  font-size: 20px;
  color: #fff;
  display: block;
  outline: 0;
}

.galary {
  padding: 0 3rem;
}
@media (max-width: 575px) {
  .contact form .form-group {
    display: block;
  }
  .contact form .form-group input {
    margin-bottom: 10px;
  }
  .galary {
    padding: 0 ;
  }
}

#business{
  background:url("./images/category.jpg");
  background-size:cover;
  background-position:center;
  overflow:hidden;
  min-height:60vh;
  padding-bottom:10px;
  z-index:-10
}

#contact{
  color: #333;
}
